<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
      /

      <router-link :to="{ name: 'rolelink' }" class="root">
        Librarian
      </router-link>
      /
      <router-link :to="{ name: 'reports' }" class="root">
        Reports
      </router-link>
      /
      <span class="child">Daily Check In/Out Register </span>
    </v-row>
    <br />

    <v-card class="card">
      <v-card-title class="heading justify-center"
        >Daily Check In/Out Register</v-card-title
      >
      <div class="">
        <div class="add-section">
          <div class="d-flex">
            <!--<v-col>
              <label class="add-text">Department</label>{{ dept }}
              <v-autocomplete
                v-model="dept"
                placeholder="Select Department"
                :items="dept_data"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
            </v-col>-->
            <v-col>
              <label class="add-text">Series</label>
              <v-autocomplete
                v-model="series"
                placeholder="Select Series"
                :items="series_data"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
            </v-col>
            <v-col>
              <label>Start Date</label>

              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="text"
                    v-model="startDate"
                    placeholder="Select Start Date"
                    append-icon="event"
                    persistent-hint
                    dense
                    readonly
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  @input="fromDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <label>End Date</label>

              <v-menu
                v-model="fromDateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="text"
                    v-model="endDate"
                    placeholder="Select End Date"
                    append-icon="event"
                    persistent-hint
                    dense
                    readonly
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  @input="fromDateMenu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </div>
          <div>
            <center>
              <v-btn color="success" class="mb-4" @click="dailyData1"
                >Fetch Data</v-btn
              >
            </center>
          </div>
        </div>
        
        <v-data-table
          :headers="headers"
          :search="search"
          :items="tabledata"
          class="elevation-5 ma-2"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title>Daily Check In/Out Register</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
             <download-excel
                class="ma-3"
                v-if="tabledata.length != 0"
                color="primary"
                dark
                :data="tabledata"
                :fields="table_fields"
                worksheet="My Worksheet"
                name="Daily_Register.xls"
              >
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>
         
          <template v-slot:item.total_issue_member="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_issue_member_id)">
                {{item.total_issue_member}}
                </span>
          </template>
          <template v-slot:item.total_return_member="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_return_member_id)">
                {{item.total_return_member}}
                </span>
          </template>
          <template v-slot:item.total_member="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_member_id)">
                {{item.total_member}}
                </span>
          </template>
          <template v-slot:item.total_issue_emp="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_issue_emp_id)">
                {{item.total_issue_emp}}
                </span>
          </template>

          <template v-slot:item.total_return_emp="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_return_emp_id)">
                {{item.total_return_emp}}
                </span>
          </template>
          <template v-slot:item.total_emp="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_emp_id)">
                {{item.total_emp}}
                </span>
          </template>
          <template v-slot:item.total_issue="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_issue_id)">
                {{item.total_issue}}
                </span>
          </template>
          <template v-slot:item.total_return="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_return_id)">
                {{item.total_return}}
                </span>
          </template>
          <template v-slot:item.total_count="{ item }">
            <span class="pointer" @click="getuseroncount(item.total_count_id)">
                {{item.total_count}}
                </span>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <!--dialog weedout-->
    <template>
      <v-row justify="center">

        <v-dialog
          v-model="dialog_book"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="close_dialog_book()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Books List</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="close_dialog_book()"> Close </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <v-card-title>
                  <v-text-field
                    v-model="search_book"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel
                      :data="memberdata"
                      :fields="header1_excel"
                      worksheet="Books Data"
                      name="BooksData.xls"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="green"
                            size="40"
                            left
                            v-bind="attrs"
                            v-on="on"
                            >mdi-file-excel</v-icon
                          >
                        </template>
                        <span>Export to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>

                <v-data-table
                  :headers="header1"
                  :items="memberdata"
                  :search="search_book"
                >
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
<!--end dialog weedout-->
    <v-overlay :value="overlay"> Loading Please Wait.. </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    overlay:false,
    series: null,
    search_book:"",
    fromDateMenu1: false,
    usertype: null,
    memberdata:[],
    valid: false,
    overlay: false,
    userid: "",
    bookitemid: "",
    selected: [],
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color:"",
    search:"",
    org: "",
    dialog_book:false,
    dialog: false,
    fromDateMenu: false,
    fromDateVal: [],
    startDate: "",
    endDate: "",
    dailyData: [],
    series_data: [],
    usertype_data: [],
    minDate: "2020-01-05",
    maxDate: new Date(),
    dept_name: "",
    user_type: "",


    headers: [
      {
        text: "Sr no",
        align: "left",
        value: "srno",
      },
      {
        text: "Date",
        align: "left",
        value: "date",
      },
      {
        text: "Student Out books",
        align: "left",
        value: "total_issue_member",
      },
       {
        text: "Student In Books",
        align: "left",
        value: "total_return_member",
      },
      {
        text: "Student Total Book Count",
        align: "left",
        value: "total_member",
      },
       {
        text: "Employee Out Books",
        align: "left",
        value: "total_issue_emp",
      },
       {
        text: "Employee In Books",
        align: "left",
        value: "total_return_emp",
      },
      {
        text: "Employee Total Book Count",
        align: "left",
        value: "total_emp",
      },
       {
        text: "Total Issue",
        align: "left",
        value: "total_issue",
      },
      {
        text: "Total Return",
        align: "left",
        value: "total_return",
      },{
        text: "Total Book Count",
        align: "left",
        value: "total_count",
      }
      
    ],
     header1: [
      {
        text: "Sr no",
        align: "left",
        value: "srno",
      },
      {
        text: "Accession No.",
        align: "left",
        value: "acc_no",
      },
      {
        text: "Title",
        align: "left",
        value: "title",
      },
       {
        text: "Author",
        align: "left",
        value: "authors",
      },
      {
        text: "Publisher",
        align: "left",
        value: "publisher",
      },
       {
        text: "Member Name",
        align: "left",
        value: "member_name",
      },
    ],
     table_fields: 
      {
        "Sr no": "srno",
        "Date": "date", 
        "Total Issue Member": "total_issue_member",
        "Total Return Member": "total_return_member",
        "Total Student Count" : "total_member",
        "Total Issue Emp": "total_issue_emp",
        "Total Return Employee": "total_return_emp",
        "Total Employee Count":"total_emp",
        "Total Issue": "total_issue",
        "Total Return": "total_return",  
        "total_count":"total_count"
      },
      header1_excel:{
        "Sr no": "srno",
        "Accession No":"acc_no",
        "Title":"title",
        "Author":"authors",
        "Publisher":"publisher",
        "member name":"member_name" 
             },
    
  }),
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    filteredDesserts() {
      return this.dailyData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  mounted() {
    this.onLoad();
  },
  methods: {
    dailyData1() {
      this.overlay = true;

      const data = {
        firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
        lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
        series_id: this.series,
        user_id: this.usertype,
        colData: this.colData,
      };
      axios
        .post("/Librarian/dailyData", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.tabledata = res.data.data;
            console.log("table data");  
            console.log(this.tabledata);
            if (this.tabledata.length == 0) {
              this.showSnackbar("#b71c1c", "No data available ");
            }
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
           this.overlay = false;
        })
        .finally(() => {});
    },
     close_dialog_book()
    {
      this.dialog_book= false;
    
    },
     getuseroncount(item) {
 
    if(item.length!=0){
      this.overlay=true;
      const data = {
        item:item,
       
      };
      axios
        .post("/Librarian/getmembers", data)
        .then((res) => {
          if (res.data.code =="200") {
            this.overlay=false;
            this.dialog_book=true;
            this.memberdata = res.data.data;
            
              
          }else
          {
            this.dialog_book=false;
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
           this.overlay = false;
        })
        .finally(() => { });
    }
     
    },
      exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Over_Due_Report", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getdeptData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
            this.usertype_data = res.data.usertype_data;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
           this.overlay = false;
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}
.c-label {
  font-weight: bold;
}
.add-section {
  display: block;
  margin: 1rem;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}
.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}
.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}
.checkbox .box {
  margin-top: 5px;
}
.pointer{
cursor: pointer;
}
.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
