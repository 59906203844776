var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":true,"color":_vm.color,"timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.snackbar_msg != null)?_c('span',[_vm._v(_vm._s(_vm.snackbar_msg))]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('v-row',{staticClass:"bradcrumb"},[_c('router-link',{staticClass:"root",attrs:{"to":{ name: 'home' }}},[_vm._v(" Dashboard ")]),_vm._v(" / "),_c('router-link',{staticClass:"root",attrs:{"to":{ name: 'rolelink' }}},[_vm._v(" Librarian ")]),_vm._v(" / "),_c('router-link',{staticClass:"root",attrs:{"to":{ name: 'reports' }}},[_vm._v(" Reports ")]),_vm._v(" / "),_c('span',{staticClass:"child"},[_vm._v("Daily Check In/Out Register ")])],1),_c('br'),_c('v-card',{staticClass:"card"},[_c('v-card-title',{staticClass:"heading justify-center"},[_vm._v("Daily Check In/Out Register")]),_c('div',{},[_c('div',{staticClass:"add-section"},[_c('div',{staticClass:"d-flex"},[_c('v-col',[_c('label',{staticClass:"add-text"},[_vm._v("Series")]),_c('v-autocomplete',{staticClass:"text",attrs:{"placeholder":"Select Series","items":_vm.series_data,"item-text":"name","item-value":"id","outlined":"","dense":"","rules":[_vm.rules.required],"clearable":"","required":""},model:{value:(_vm.series),callback:function ($$v) {_vm.series=$$v},expression:"series"}})],1),_c('v-col',[_c('label',[_vm._v("Start Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"text",attrs:{"placeholder":"Select Start Date","append-icon":"event","persistent-hint":"","dense":"","readonly":"","outlined":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',[_c('label',[_vm._v("End Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"text",attrs:{"placeholder":"Select End Date","append-icon":"event","persistent-hint":"","dense":"","readonly":"","outlined":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}]),model:{value:(_vm.fromDateMenu1),callback:function ($$v) {_vm.fromDateMenu1=$$v},expression:"fromDateMenu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.fromDateMenu1 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('div',[_c('center',[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"success"},on:{"click":_vm.dailyData1}},[_vm._v("Fetch Data")])],1)],1)]),_c('v-data-table',{staticClass:"elevation-5 ma-2",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.tabledata,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white","align":"center","justify":"center"}},[_c('v-toolbar-title',[_vm._v("Daily Check In/Out Register")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.tabledata.length != 0)?_c('download-excel',{staticClass:"ma-3",attrs:{"color":"primary","dark":"","data":_vm.tabledata,"fields":_vm.table_fields,"worksheet":"My Worksheet","name":"Daily_Register.xls"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-excel")])],1)],1):_vm._e()],1)]},proxy:true},{key:"item.total_issue_member",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_issue_member_id)}}},[_vm._v(" "+_vm._s(item.total_issue_member)+" ")])]}},{key:"item.total_return_member",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_return_member_id)}}},[_vm._v(" "+_vm._s(item.total_return_member)+" ")])]}},{key:"item.total_member",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_member_id)}}},[_vm._v(" "+_vm._s(item.total_member)+" ")])]}},{key:"item.total_issue_emp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_issue_emp_id)}}},[_vm._v(" "+_vm._s(item.total_issue_emp)+" ")])]}},{key:"item.total_return_emp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_return_emp_id)}}},[_vm._v(" "+_vm._s(item.total_return_emp)+" ")])]}},{key:"item.total_emp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_emp_id)}}},[_vm._v(" "+_vm._s(item.total_emp)+" ")])]}},{key:"item.total_issue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_issue_id)}}},[_vm._v(" "+_vm._s(item.total_issue)+" ")])]}},{key:"item.total_return",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_return_id)}}},[_vm._v(" "+_vm._s(item.total_return)+" ")])]}},{key:"item.total_count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.getuseroncount(item.total_count_id)}}},[_vm._v(" "+_vm._s(item.total_count)+" ")])]}}])})],1)],1),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog_book),callback:function ($$v) {_vm.dialog_book=$$v},expression:"dialog_book"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"gray"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close_dialog_book()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Books List")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.close_dialog_book()}}},[_vm._v(" Close ")])],1)],1),_c('br'),_c('v-card-text',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_book),callback:function ($$v) {_vm.search_book=$$v},expression:"search_book"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.memberdata,"fields":_vm.header1_excel,"worksheet":"Books Data","name":"BooksData.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}])},[_c('span',[_vm._v("Export to Excel")])])],1)]],2),_c('v-data-table',{attrs:{"headers":_vm.header1,"items":_vm.memberdata,"search":_vm.search_book}})],1)],1)],1)],1)],1)],_c('v-overlay',{attrs:{"value":_vm.overlay}},[_vm._v(" Loading Please Wait.. ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }